.test {
  width: 32.8rem;
  height: 100%;
  margin-bottom: 3rem;
}
.test .test_title {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.test .test_title .test_name {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #5d5a6f;
}
.test .test_title .test_title_text {
  margin-left: 0.6rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #5d5a6f;
}
.test .test_ques_no {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #0a033c;
  margin-bottom: 1.1rem;
}
.test .test_ques {
  width: 32.8rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #5d5a6f;
  margin-bottom: 3.3rem;
}
.test .test_option {
  margin-bottom: 3.6rem;
}
.test .test_option .test_check {
  width: 29.8rem;
  height: 4rem;
  border-radius: 5px;
  padding: 0.875rem;
  background: #ffffff;
  margin-bottom: 1rem;
  border: 1px solid #dddddd;
  cursor: pointer;
  display: flex;
}
.test .test_option .test_check .test_check_input {
  width: 2.2rem;
  height: 2.2rem;
  align-self: center;
  cursor: pointer;
}
.test .test_option .test_check .test_check_label {
  position: relative;
  top: 0.2rem;
  cursor: pointer;
  display: flex;
}
.test .test_option .test_check .test_check_label p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5d5a6f;
}
.test .test_option .test_check .test_check_label span {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #0a033c;
  margin-right: 0.7rem;
  margin-left: 1.5rem;
}
.test .test_show {
  width: 29.8rem;
  height: 4rem;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: -0.2rem 0.2rem 0.4rem rgba(235, 242, 249, 0.5);
  border-radius: 2rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  justify-content: space-between;
  z-index: 90;
}
.test .test_show_t {
  background: #b0e5e9;
  border-radius: 11px;
  width: 11.9rem;
  height: 2.4rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.test .test_show_t p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  text-align: center;
}
.test .test_hide_t {
  background: #ffffff;
  border-radius: 11px;
  width: 11.9rem;
  height: 2.4rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.test .test_hide_t p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  text-align: center;
}
.test .test_expl {
  width: 29.8rem;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 4px rgba(168, 164, 164, 0.25);
  border-radius: 2rem;
  z-index: 30;
  margin-top: -4rem;
}
.test .test_expl .test_txt {
  padding: 4.5rem 2rem 2rem 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5d5a6f;
}/*# sourceMappingURL=FreeAnswer.css.map */
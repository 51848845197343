.test_status {
  width: 28.4rem;
  height: 12.6rem;
  border: 0.1rem solid #ffd593;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.test_status .test_status_subject {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: #0a033c;
  text-decoration: none;
}
.test_status .test_status_diff {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  margin-bottom: 0.6rem;
  margin-top: 0.3rem;
}
.test_status .test_status_no_of_qs {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #5d5a6f;
  margin-bottom: 0.6rem;
}
.test_status .test_status_time {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #5d5a6f;
}/*# sourceMappingURL=TestStatus.css.map */
.header {
  width: 31.1rem;
  height: 11.6rem;
  background: linear-gradient(
    180deg,
    rgba(255, 208, 208, 0.26) 0%,
    rgba(255, 255, 255, 0.22) 100%
  );
  border-radius: 2rem;
  padding: 1.3rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header_det {
    .header_title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 3.4rem;
      color: #0a033c;
      margin-bottom: 0.6rem;
    }
    .header_txt {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 120%;
      color: #0a033c;
    }
  }
  .header_logo {
    img {
      width: 7.628rem;
      height: 9rem;
    }
  }
}

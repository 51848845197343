.studySVG {
  width: 31.1rem;
  height: 44rem;
  text-align: center;

  img {
    width: 20rem;
    height: 23.51;
    margin-bottom: 2rem;
  }
  .studySVG_det {
    .studySVG_text {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #5d5a6f;
      margin-bottom: 1rem;
    }
    .studySVG_title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.3rem;
      margin-bottom: 1.5rem;
    }
    .studySVG_det_text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 150.5%;
      letter-spacing: 0.01em;
      color: #5d5a6f;
      margin-bottom: 1.5rem;
    }
  }
  .studySVG_btn {
    display: flex;
    justify-content: space-evenly;
  }
}

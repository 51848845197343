.forgot {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .forgot_box {
    padding: 4rem 3.8rem;
    text-align: center;
    // border: 0.1rem solid #c4c4c4;

    .forgot-name {
      margin-bottom: 2rem;
    }
    .forgot-para {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 3.3rem;
    }

    .forgot-email {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      color: red;
      input {
        z-index: 5;
        width: 29.8rem;
        height: 4rem;
        outline: none;
        color: #0a033c;
        padding: 0rem 1.8rem;
        background: #ffffff;
        border-radius: 0.5rem;
        border: 1px solid #c4c4c4;
        margin-bottom: 1.8rem;
        display: block;
      }
    }
    .forgot-btn {
      display: flex;
      justify-content: space-between;
    }
  }
}

.container {
  width: 37.5rem;
  margin: auto;
}
.container .mentor_cont {
  width: 31.6rem;
  margin: auto;
}
.container .mentor_cont .mentor_header {
  width: 31.1rem;
  margin: 0rem auto 4.4rem auto;
}
.container .mentor_cont .mentor_asign {
  width: 31.1rem;
  margin: 0rem auto 4.4rem auto;
}
.container .mentor_cont .mentor_asign .mentor_asign_header {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.02em;
  margin-bottom: 2rem;
  color: #000000;
}
.container .mentor_cont .mentor_asign .mentor_asign_mentor {
  width: 31.6rem;
  margin: 0rem auto 7rem auto;
}/*# sourceMappingURL=Mentor.css.map */
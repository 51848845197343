.navbar {
  min-width: 375px;
  height: 6.5rem;
  background: #ffffff;
  display: flex;
  .nav {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 10px;

    .nav_items {
      width: 11.3rem;
      display: flex;
      list-style-type: none;
      align-items: center;
      justify-content: flex-end;

      a {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-decoration: none;
        display: block;
        letter-spacing: 0.03em;
        color: #5d5a6f;
        text-transform: uppercase !important;
        font-weight: 400 !important;
        margin-right: 20px !important;
      }

      .hamburger-menu {
        label {
          font-size: 2.6rem;
        }

        input {
          display: none;
        }
      }
      .dropdown {
        display: block;
        position: relative;
        z-index: 1000;

        .dropbtn {
          background: #ff9900;
          color: white;
          width: 9.5rem;
          height: 3.5rem;
          font-size: 1.6rem;
          font-weight: 600;
          border: none;
          cursor: pointer;
        }

        .dropdown-content {
          position: absolute;
          background-color: #f1f1f1;
          min-width: 9.5rem;
          padding: 0.5rem 0.5rem;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          cursor: pointer;
          right: 0px;
          list-style: none;

          a {
            color: black;
            padding: 1rem 0.5rem;
            text-decoration: none;
            display: block;
          }
          li {
            display: flex;
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
}

.interactiveSVG {
  width: 31.1rem;
  height: 51rem;
}
.interactiveSVG .interactiveSVG_det {
  width: 31.1rem;
  text-align: center;
}
.interactiveSVG .interactiveSVG_det .interactiveSVG_det_title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
  letter-spacing: 0.02em;
  color: #0a033c;
  margin: 3rem 0rem 2rem 0rem;
}
.interactiveSVG .interactiveSVG_det .interactiveSVG_det_text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.055em;
  color: #5d5a6f;
  margin-bottom: 2rem;
}
.interactiveSVG .interactiveSVG_img img {
  width: 28.257rem;
  height: 30rem;
}/*# sourceMappingURL=InteractiveSVG.css.map */
.otp {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp .otp_box {
  padding: 4rem 3.8rem;
  text-align: center;
}
.otp .otp_box .otp_name {
  margin-bottom: 2rem;
}
.otp .otp_box .otp_para {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 3.3rem;
}
.otp .otp_box .otp-email {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: red;
}
.otp .otp_box .otp-email input {
  z-index: 5;
  width: 29.8rem;
  height: 4rem;
  outline: none;
  color: #0a033c;
  padding: 0rem 1.8rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #c4c4c4;
  margin-bottom: 1.8rem;
  display: block;
}
.otp .otp_box .otp-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.otp .otp_box .otp_resend .otp_resend_click {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #0a033c;
}
.otp .otp_box .otp_resend .otp_clickResend {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #404dbf;
  cursor: pointer;
}/*# sourceMappingURL=OTP.css.map */
.mock_cont {
  width: 32.8rem;
  margin: auto;
}
.mock_cont .mock_mockh {
  margin-top: 3.5rem;
}
.mock_cont .mock_test {
  width: 32.8rem;
  margin: 3.5rem auto;
}
.mock_cont .mock_test .test {
  width: 32.8rem;
  height: 100%;
}
.mock_cont .mock_test .test .test_title {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.mock_cont .mock_test .test .test_title .test_name {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #5d5a6f;
}
.mock_cont .mock_test .test .test_title .test_title_text {
  margin-left: 0.6rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #5d5a6f;
}
.mock_cont .mock_test .test .test_ques_no {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #0a033c;
  margin-bottom: 1.1rem;
}
.mock_cont .mock_test .test .test_ques {
  width: 32.8rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #5d5a6f;
  margin-bottom: 3.3rem;
}
.mock_cont .mock_test .test .test_option {
  margin-bottom: 3.6rem;
}
.mock_cont .mock_test .test .test_option .test_check {
  height: 100%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dddddd;
  width: 32rem;
  padding: 0.875rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
}
.mock_cont .mock_test .test .test_option .test_check .test_check_input {
  min-width: 2.2rem;
  min-height: 2.2rem;
  cursor: pointer;
}
.mock_cont .mock_test .test .test_option .test_check .test_check_label {
  position: relative;
  top: 0.2rem;
  cursor: pointer;
  display: flex;
}
.mock_cont .mock_test .test .test_option .test_check .test_check_label p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  width: 22.5rem;
  display: flex;
  flex-wrap: wrap;
}
.mock_cont .mock_test .test .test_option .test_check .test_check_label span {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #0a033c;
  margin-right: 0.7rem;
  margin-left: 1.5rem;
}
.mock_cont .mock_test .test .test_show {
  width: 32rem;
  height: 4rem;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: -0.2rem 0.2rem 0.4rem rgba(235, 242, 249, 0.5);
  border-radius: 2rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  justify-content: space-between;
  z-index: 90;
}
.mock_cont .mock_test .test .test_show_t {
  background: #b0e5e9;
  border-radius: 11px;
  width: 13.4rem;
  height: 2.4rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.mock_cont .mock_test .test .test_show_t p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  text-align: center;
}
.mock_cont .mock_test .test .test_hide_t {
  background: #ffffff;
  border-radius: 11px;
  width: 13.4rem;
  height: 2.4rem;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.mock_cont .mock_test .test .test_hide_t p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  text-align: center;
}
.mock_cont .mock_test .test .test_expl {
  width: 32rem;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 4px rgba(168, 164, 164, 0.25);
  border-radius: 2rem;
  z-index: 30;
  margin-top: -4rem;
}
.mock_cont .mock_test .test .test_expl .test_txt {
  padding: 4.5rem 2rem 2rem 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5d5a6f;
}
.mock_cont .mock_test .test .test_expl .test_txt p {
  margin-left: 1rem;
}
.mock_cont .mock_test .test .test_expl .test_txt .test_text {
  display: flex;
  padding: 1rem 0rem;
}
.mock_cont .mock_btn {
  width: 31rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3.5rem auto;
}
.mock_cont .mock_btn Button {
  margin-bottom: 1rem;
}/*# sourceMappingURL=MockFree.css.map */
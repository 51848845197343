.schedule {
  width: 31.6rem;
  height: 11.5rem;
  background: #ffffff;
  box-shadow: -0.2rem 0.2rem 0.5rem rgba(235, 242, 249, 0.5),
    0.2rem -0.2rem 0.5rem rgba(235, 242, 249, 0.5);
  border-radius: 2rem;
  padding: 1.4rem 1.5rem;
  display: flex;
  margin-bottom: 3rem;

  .schedule_det_l {
    width: 9rem;
    text-align: center;

    .schedule_rating {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      margin-left: 0.4rem;

      .schedule_rate {
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #ff6652;
      }
      .schedule_customer {
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #5d5a6f;
      }
    }
    .schedule_profile {
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.3rem;
      color: #404dbf;
    }
  }
  .schedule_det_r {
    .schedule_title {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #0a033c;
      margin-bottom: 0.9rem;
      margin-top: 0.9rem;
    }
    .schedule_txt {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #5d5a6f;
      margin-bottom: 0.9rem;
    }
  }
}

.assign_cont {
  width: 32.8rem;
  margin: auto;
}
.assign_cont .assign_mockh {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.assign_cont .review {
  width: 29.8rem;
  height: 23.5rem;
  background: #fcfcfc;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 3rem;
}
.assign_cont .review .column {
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  cursor: pointer;
}
.assign_cont .review .column p {
  font-size: 1.4rem;
  position: relative;
  left: 3rem;
  color: #5d5a6f;
}
.assign_cont .review .column .num-box {
  width: 2.4rem;
  height: 2.4rem;
  background: #00df76;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.assign_cont .review .column .num-box .num {
  color: #fff;
  font-size: 1.4rem;
}
.assign_cont .review .column #num-box1 {
  background-color: #00df76;
}
.assign_cont .review .column #num-box2 {
  background-color: #ff6652;
}
.assign_cont .review .column #num-box3 {
  background-color: #ffc107;
}
.assign_cont .review .column #num-box4 {
  background-color: #07e5ca;
}
.assign_cont .review .column #num-box5 {
  background-color: #ea8fea;
}
.assign_cont .assignM_btn {
  width: 31rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3.5rem auto;
}
.assign_cont .assignM_btn Button {
  margin-bottom: 1rem;
}/*# sourceMappingURL=TestSummary.css.map */
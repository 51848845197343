.container {
  width: 37.5rem;
  margin: auto;
}
.container .home_container {
  width: 32.8rem;
  margin: auto;
}
.container .home_container .home_studySVG {
  width: 31.1rem;
  margin: 0rem auto 4rem auto;
}
.container .home_container .home_exam {
  width: 28.4rem;
  margin: 0rem auto 4rem auto;
}
.container .home_container .home_liveSVG {
  width: 23rem;
  margin: 0rem auto 4rem auto;
}
.container .home_container .home_chatSVG {
  width: 30.8rem;
  margin: 0rem auto 4rem auto;
}
.container .home_container .home_interactiveSVG {
  width: 31.1rem;
  margin: 0rem auto 4rem auto;
}/*# sourceMappingURL=Home.css.map */
.container {
  width: 37.5rem;
  margin: auto;
  .terms {
    width: 32.8rem;
    margin: auto;
    .terms_heading {
      font-size: 2rem;
      font-weight: 600;
    }
    .terms_para {
      margin: 2.4rem auto;
      .terms_title {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        font-weight: 500;
        color: #0a033c;
      }
      .terms_description {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        color: #5d5a6f;
                text-align: justify;
      }
    }
  }
}

.mocktesth {
  width: 32.8rem;
  height: 3.9rem;
  background: #ffffff;
  border-radius: 3rem;
  padding: 0.9rem 2.8rem;
  box-shadow: -0.2rem 0.2rem 0.5rem rgba(235, 242, 249, 0.5), 0.2rem -0.2rem 0.5rem rgba(235, 242, 249, 0.5);
}
.mocktesth .mock_det {
  width: 27.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mocktesth .mock_det .mocktesth_count {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;
  color: #5d5a6f;
}
.mocktesth .mock_det .mocktesth_time {
  width: 7.4rem;
  display: flex;
  justify-content: space-between;
}
.mocktesth .mock_det .mocktesth_time .mocktesth_time_text {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 120%;
  color: #5d5a6f;
}/*# sourceMappingURL=MockTestH.css.map */
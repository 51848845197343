.container {
  width: 37.5rem;
  margin: auto;
  .policy {
    width: 32.8rem;
    margin: auto;
    .policy_heading {
      font-size: 2.2rem;
    }
    .policy_para {
      margin: 2.4rem auto;
      .policy_title {
        font-size: 1.6rem;
          margin-bottom: 1rem;
          font-weight: 500;
          color: #0a033c;
      }
      .policy_description {
        font-size: 1.4rem;
        margin-left: 1.4rem;
        margin-bottom: 0.5rem;
        color: #5d5a6f;
                text-align: justify;
      }
            .policy_descrip{
                      font-size: 1.4rem;
                        // margin-left: 1.4rem;
                        margin-bottom: 0.5rem;
                        color: #5d5a6f;
                                text-align: justify;
            }
    }
  }
}

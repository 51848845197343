.navbar {
  min-width: 375px;
  height: 6.5rem;
  background: #ffffff;
  display: flex;
}
.navbar .nav {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 10px;
}
.navbar .nav .nav_items {
  width: 11.3rem;
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: flex-end;
}
.navbar .nav .nav_items a {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-decoration: none;
  display: block;
  letter-spacing: 0.03em;
  color: #5d5a6f;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  margin-right: 20px !important;
}
.navbar .nav .nav_items .hamburger-menu label {
  font-size: 2.6rem;
}
.navbar .nav .nav_items .hamburger-menu input {
  display: none;
}
.navbar .nav .nav_items .dropdown {
  display: block;
  position: relative;
  z-index: 1000;
}
.navbar .nav .nav_items .dropdown .dropbtn {
  background: #ff9900;
  color: white;
  width: 9.5rem;
  height: 3.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
}
.navbar .nav .nav_items .dropdown .dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 9.5rem;
  padding: 0.5rem 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
  right: 0px;
  list-style: none;
}
.navbar .nav .nav_items .dropdown .dropdown-content a {
  color: black;
  padding: 1rem 0.5rem;
  text-decoration: none;
  display: block;
}
.navbar .nav .nav_items .dropdown .dropdown-content li {
  display: flex;
  padding: 0 0.5rem;
}/*# sourceMappingURL=Navbar.css.map */
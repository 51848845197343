.ratings {
  margin-bottom: 1.8rem;
  .ratings_item {
    display: flex;
    align-items: center;
    margin-bottom: 1.8rem;
    .review {
      width: 5.5rem;
      height: 2.2rem;
      background: #43b055;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .review_ratings {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #ffffff;
        margin-left: 0.5rem;
      }
    }
    .review_txt {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #0a033c;
      margin-left: 1.3rem;
    }
  }
  .rating_desp {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #5d5a6f;
    margin-bottom: 1.5rem;
  }
  .rating_customer {
    width: 20rem;
    display: flex;
    justify-content: space-between;

    .customer_name {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #5d5a6f;
      margin-bottom: 2.4rem;
    }
  }
  hr {
    border: 1px solid #ebf2f9;
  }
}

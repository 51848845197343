@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ############################## html element styling ##############################*/
html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  /* width: 37.5rem; */
  margin: auto;
}

.error {
  color: red;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

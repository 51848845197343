.question {
  width: 29.8rem;
  height: 100%;
  padding: 1.9rem;
  background: #fcfcfc;
  border-radius: 0.5rem;
  margin: 3rem auto;
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    width: 100%;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;

    .num-box {
      width: 4rem;
      height: 4rem;
      border-radius: 20px 0px 0px 0px;
      margin: 0.9rem;
      .num {
        color: #fff;
        font-size: 1.4rem;
        position: relative;
        left: 1.5rem;
        top: 1.5rem;
        font-weight: 500;
      }
    }
    #num-box1 {
      background-color: #00df76;
    }
    #num-box2 {
      background-color: #ff6652;
    }
    #num-box3 {
      background-color: #ffc107;
    }
    #num-box4 {
      background-color: #07e5ca;
    }
  }
}

.mentorc {
  width: 31.1rem;
  height: 11.6rem;
  padding: 0.8rem 2.9rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 209, 209, 0.26) 0%, rgba(255, 255, 255, 0.22) 100%);
}
.mentorc .mentorc_det {
  margin-left: 2rem;
}
.mentorc .mentorc_det .mentorc_title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #0a033c;
  margin-bottom: 0.3rem;
}
.mentorc .mentorc_det .mentorc_txt {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #5d5a6f;
  margin-bottom: 1rem;
}
.mentorc .mentorc_det .mentorc_rating {
  display: flex;
  align-items: center;
}
.mentorc .mentorc_det .mentorc_rating .mentorc_rate {
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #ff6652;
}
.mentorc .mentorc_det .mentorc_rating .mentorc_customer {
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #5d5a6f;
}/*# sourceMappingURL=MentorC.css.map */
.container {
  width: 37.5rem;
  margin: auto;
  .home_container {
    width: 32.8rem;
    margin: auto;
    .home_studySVG {
      width: 31.1rem;
      margin: 0rem auto 4rem auto;
    }
    .home_exam {
      width: 28.4rem;
      margin: 0rem auto 4rem auto;
    }

    .home_liveSVG {
      width: 23rem;
      margin: 0rem auto 4rem auto;
    }
    .home_chatSVG {
      width: 30.8rem;
      margin: 0rem auto 4rem auto;
    }
    .home_interactiveSVG {
      width: 31.1rem;
      margin: 0rem auto 4rem auto;
    }
  }
}

.container {
  width: 37.5rem;
  margin: auto;
}
.container .policy {
  width: 32.8rem;
  margin: auto;
}
.container .policy .policy_heading {
  font-size: 2.2rem;
}
.container .policy .policy_para {
  margin: 2.4rem auto;
}
.container .policy .policy_para .policy_title {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #0a033c;
}
.container .policy .policy_para .policy_description {
  font-size: 1.4rem;
  margin-left: 1.4rem;
  margin-bottom: 0.5rem;
  color: #5d5a6f;
  text-align: justify;
}
.container .policy .policy_para .policy_descrip {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #5d5a6f;
  text-align: justify;
}/*# sourceMappingURL=Policy.css.map */
.container {
  width: 37.5rem;
  margin: auto;
}
.container .about {
  width: 34.8rem;
  margin: auto;
}
.container .about .about_heading {
  font-size: 2rem;
  font-weight: 600;
}
.container .about .about_para {
  margin: 2.4rem auto;
}
.container .about .about_para .about_title {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #0a033c;
}
.container .about .about_para .about_description {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  color: #5d5a6f;
  text-align: justify;
}/*# sourceMappingURL=About.css.map */
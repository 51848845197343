.container {
  width: 37.5rem;
  margin: auto;
}
.container .filter_cont {
  width: 37.5rem;
  margin: auto;
}
.container .filter_cont .subject {
  width: 37.5rem;
  margin: 0rem auto 4rem auto;
}
.container .filter_cont .subject .subject_title {
  font-weight: 600;
  font-size: 2rem;
  color: #0a033c;
  line-height: 120%;
  margin-bottom: 2.8rem;
  text-align: center;
}
.container .filter_cont .subject .subject_option {
  flex-wrap: wrap;
  display: flex;
  gap: 1.6rem;
  margin-left: 1rem;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.container .filter_cont .subject .subject_option .subject-select {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.container .filter_cont .subject .subject_option .subject-select label {
  cursor: pointer;
}
.container .filter_cont .subject .subject_option .subject-select label input[type=radio] {
  display: none;
}
.container .filter_cont .subject .subject_option .subject-select label input:checked ~ span {
  border: 1px solid #ffd593;
  background: #ffd593;
  color: #0a033c;
}
.container .filter_cont .subject .subject_option .subject-select label span {
  border: 0.1rem solid #8f8d9a;
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
  color: #5d5a6f;
}
.container .filter_cont .subject .subject_option2 {
  width: 90%;
  height: 100%;
  margin: auto;
}
.container .filter_cont .quest {
  width: 30rem;
  margin: 0rem auto 4rem auto;
}
.container .filter_cont .quest .quest_title {
  font-weight: 600;
  font-size: 2rem;
  color: #0a033c;
  line-height: 120%;
  text-align: center;
  margin-bottom: 2.8rem;
}
.container .filter_cont .quest .quest_option {
  display: flex;
  gap: 1.6rem;
  margin-left: 1rem;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.container .filter_cont .quest .quest_option .quest_select {
  margin-bottom: 2rem;
}
.container .filter_cont .quest .quest_option .quest_select label {
  cursor: pointer;
}
.container .filter_cont .quest .quest_option .quest_select label input[type=radio] {
  display: none;
}
.container .filter_cont .quest .quest_option .quest_select label input:checked ~ span {
  border: 1px solid #ffd593;
  background: #ffd593;
  color: #0a033c;
}
.container .filter_cont .quest .quest_option .quest_select label span {
  border: 0.1rem solid #8f8d9a;
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
  color: #5d5a6f;
}
.container .filter_cont .quest .quest_option .quest_select_in {
  border: 0.1rem solid #8f8d9a;
  border-radius: 2rem;
  text-align: center;
  padding: 1rem 2rem;
  margin: auto;
}
.container .filter_cont .quest .quest_option .quest_select_in input {
  outline: none;
  border: transparent;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
  color: #5d5a6f;
}
.container .filter_cont .quest .quest_or {
  font-weight: 600;
  font-size: 2rem;
  color: #0a033c;
  margin: 2rem 0rem;
  text-align: center;
}
.container .filter_cont .filter_btn {
  width: 23.9rem;
  margin: 0rem auto 8rem auto;
}
.container .filter_cont .filter_btn input {
  color: #5d5a6f;
  font-size: 1.6rem;
  background-color: #b0e5e9;
  width: 21.1rem;
  height: 3.9rem;
  border: 0.2rem solid #b0e5e9;
  border-radius: 2rem;
}/*# sourceMappingURL=FilterNoLog.css.map */
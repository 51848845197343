.assign_cont {
  width: 32.8rem;
  margin: auto;

  .assign_mockh {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .review {
    width: 29.8rem;
    height: 23.5rem;
    background: #fcfcfc;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 3rem;
    .column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-bottom: 2rem;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 1.4rem;
        position: relative;
        left: 3rem;
        color: #5d5a6f;
      }

      .num-box {
        width: 2.4rem;
        height: 2.4rem;
        background: #00df76;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .num {
          color: #fff;
          font-size: 1.4rem;
        }
      }
      #num-box1 {
        background-color: #00df76;
      }
      #num-box2 {
        background-color: #ff6652;
      }
      #num-box3 {
        background-color: #ffc107;
      }
      #num-box4 {
        background-color: #07e5ca;
      }
      #num-box5 {
        background-color: #ea8fea;
      }
    }
  }
  .assignM_btn {
    width: 31rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3.5rem auto;
    Button {
      margin-bottom: 1rem;
    }
  }
}

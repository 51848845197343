.exam {
  width: 28.4rem;
  height: 9rem;
  background: #ffffff;
  border: 0.1rem solid #b0e5e9;
  border-radius: 1rem;
  padding: 1.5rem 0rem;
  text-align: center;
  margin-bottom: 1.5rem;
  cursor: pointer;

  .exam_title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.451rem;
    color: #0a033c;
    margin-bottom: 1.6rem;
  }
  .exam_test {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.875rem;
    color: #5d5a6f;
  }
}

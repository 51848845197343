.loader {
  width: 14.1rem;
  height: 14.1rem;
  background-color: #ffffff75;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 99999;
}
.loader .loadingio-spinner-spinner-vxs5vj4ce0o {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes boom {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.boom div {
  left: 66.27px;
  top: 33.839999999999996px;
  position: absolute;
  animation: boom linear 1s infinite;
  background: #b0e5e9;
  width: 8.459999999999999px;
  height: 16.919999999999998px;
  border-radius: 4.2299999999999995px / 8.459999999999999px;
  transform-origin: 4.2299999999999995px 36.66px;
}
.boom div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #b0e5e9;
}
.boom div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #b0e5e9;
}
.boom div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #b0e5e9;
}
.boom div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #b0e5e9;
}
.boom div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #b0e5e9;
}
.boom div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #b0e5e9;
}
.boom div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #b0e5e9;
}
.boom div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #b0e5e9;
}
.boom div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #b0e5e9;
}
.boom div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #b0e5e9;
}
.boom div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #b0e5e9;
}
.boom div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #b0e5e9;
}
.loadingio-spinner-spinner-vxs5vj4ce0o {
  width: 141px;
  height: 141px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.boom {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.boom div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.signin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin .signin-box {
  padding: 6.7rem 3.8rem;
  text-align: center;
}
.signin .signin-box .signin-name {
  margin-bottom: 2rem;
}
.signin .signin-box .signin-para {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 5rem;
}
.signin .signin-box .signin-form {
  margin-bottom: 5.7rem;
}
.signin .signin-box .signin-form .signin-email {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: red;
}
.signin .signin-box .signin-form .signin-email input {
  z-index: 5;
  width: 29.8rem;
  height: 4rem;
  outline: none;
  color: #0a033c;
  padding: 0rem 1.8rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #c4c4c4;
  margin-bottom: 1.8rem;
  display: block;
}
.signin .signin-box .signin-form .signin-forget {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration-line: underline;
  color: #404dbf;
  margin-bottom: 1.4rem;
}
.signin .signin-box .signin-form .signin-forget a {
  color: #404dbf;
}
.signin .signin-box .signin-account {
  width: 29.8rem;
  display: flex;
  justify-content: space-between;
}
.signin .signin-box .signin-account p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.17rem;
  color: #0a033c;
}
.signin .signin-box .signin-account .signup-link a {
  font-size: 1.6rem;
  line-height: 2.17rem;
  font-weight: 400;
  text-decoration-line: underline;
  color: #404dbf;
  cursor: pointer;
}
.signin .signin-box .signup-back {
  width: 10rem;
  height: 3rem;
  border: 0.1rem solid #c6c6c6;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}
.signin .signin-box .signup-back a {
  cursor: pointer;
  font-size: 1.7rem;
  line-height: 2.3rem;
  font-weight: 400;
  text-decoration-line: none;
  color: #0a033c;
}/*# sourceMappingURL=Signin.css.map */
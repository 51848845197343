// Media Queries

// IPads or Tablets

.footer {
  width: 37.5rem;
  height: 100%;
  background: #f8f2ff;
  padding: 2.7rem 11.2rem;

  .foot {
    .foot-items {
      width: 12rem;
      align-items: stretch;
      list-style-type: none;
      margin-bottom: 2.5rem;

      .foot-item {
        margin-bottom: 1rem;
      }
      .foot-item a {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #5d5a6f;
        text-decoration: none;
      }
    }
  }
}

.liveSVG {
  width: 23rem;
  height: 35.6rem;
  margin-bottom: 1rem;
}
.liveSVG img {
  width: 20rem;
  height: 20rem;
}
.liveSVG .liveSVG_det .liveSVG_det_title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #0a033c;
  margin-bottom: 1.5rem;
}
.liveSVG .liveSVG_det .liveSVG_det_ul {
  margin-bottom: 1.5rem;
}
.liveSVG .liveSVG_det .liveSVG_det_ul .liveSVG_ul_text {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #5d5a6f;
  margin-bottom: 0.7rem;
  margin-left: 1.4rem;
}
.liveSVG .liveSVG_det .liveSVG_btn {
  width: 13.2rem;
  margin: auto;
}/*# sourceMappingURL=LiveSVG.css.map */
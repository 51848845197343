.ChatSVG {
  width: 30.8rem;
  height: 57.6rem;
  align-items: center;

  .CharSVG_det {
    width: 30.8rem;
    text-align: center;

    .ChatSVG_det_title {
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.7rem;
      letter-spacing: 0.02em;
      color: #0a033c;
      margin-bottom: 2.3rem;
      margin-top: 1.5rem;
    }
    .ChatSVG_det_text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #5d5a6f;
      margin-bottom: 2.3rem;
    }
  }
  .ChatSVG_img {
    width: 19.4rem;
    height: 30rem;
    margin: auto;
    img {
      width: 19.4rem;
      height: 30rem;
    }
  }
}
// }

.container {
  width: 37.5rem;
  margin: auto;
}
.container .account_cont {
  width: 31.3rem;
  margin: auto;
}
.container .account_cont .account_header {
  width: 31.1rem;
  margin: 0rem auto 4.4rem auto;
}
.container .account_cont .acc_asign {
  width: 31.1rem;
  margin: 0rem auto 4.4rem auto;
}
.container .account_cont .acc_asign .acc_asign_head {
  display: flex;
  justify-content: space-between;
  border: 1px solid #b0e5e9;
  border-radius: 0.7rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.container .account_cont .acc_asign .acc_asign_head .acc_asign_header {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.02em;
  color: #0a033c;
}
.container .account_cont .acc_asign .acc_asign_head svg {
  font-size: 2.5rem;
}
.container .account_cont .acc_asign .acc_asign_mentor {
  width: 28.4rem;
  margin: auto;
}
.container .account_cont .acc_asign .acc_asign_mentor .result-text {
  font-size: 1.4rem;
  text-align: center;
}/*# sourceMappingURL=Account.css.map */
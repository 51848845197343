.container {
  width: 37.5rem;
  margin: auto;
}
.container .md_cont {
  width: 32.2rem;
  margin: auto;
}
.container .md_cont .md_header {
  width: 31.1rem;
  margin: 0rem auto 4rem auto;
}
.container .md_cont .md_btn {
  width: 31.1rem;
  margin: 0rem auto 3.2rem auto;
}
.container .md_cont .md_btn .md_btn_s {
  margin-bottom: 1.6rem;
}
.container .md_cont .md_det {
  margin-bottom: 30rem;
}
.container .md_cont .md_det .md_about {
  width: 32rem;
  margin: 0rem auto 2rem auto;
}
.container .md_cont .md_det .md_about .md_about_title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #0a033c;
  margin-bottom: 1rem;
}
.container .md_cont .md_det .md_about .md_about_txt {
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #5d5a6f;
}/*# sourceMappingURL=MentorDetails.css.map */